<template>
  <MosaicUiCoreSidebarMenuItem
    label="cabinet-psychologist.sidebar.calendar"
    route="/cabinet/psychologist/calendar"
    icon="ion:calendar-outline"
  />
  <MosaicUiCoreSidebarMenuItem
    label="cabinet-psychologist.sidebar.faq"
    route="/cabinet/psychologist/faq"
    icon="mdi:cloud-question-outline"
  />
  <MosaicUiCoreSidebarMenuItem
    label="cabinet-psychologist.sidebar.my-customers"
    route="/cabinet/psychologist/my-customers"
    icon="ph:user-list"
  />
  <MosaicUiCoreSidebarMenuItem
    label="cabinet-psychologist.sidebar.google"
    route="/cabinet/psychologist/google-calendars"
    icon="iconoir:google"
  />
  <MosaicUiCoreSidebarMenuItem
    label="cabinet-psychologist.sidebar.chat"
    route="/cabinet/chat"
    icon="ion:chatbubbles-outline"
  />
  <MosaicUiCoreSidebarMenuItem
    label="cabinet-psychologist.sidebar.payouts"
    route="/cabinet/psychologist/payouts"
    icon="ion:card-outline"
  />
  <MosaicUiCoreSidebarMenuItem
    label="cabinet-psychologist.sidebar.profile"
    route="/cabinet/psychologist/profile"
    icon="carbon:user-profile"
  />
</template>

<script setup lang="ts">
const { t } = useI18n();
</script>

<style scoped>

</style>
